
import router from '@/router';
import { defineComponent, reactive, ref } from 'vue';



export default defineComponent({
  name: 'Home',
  setup(){
    const players: string[] = reactive([])

    const addPlayer = () => {
      players.push("")
    }
    const selected_cluedo = ref("")
    const startCluedo = () => {
      router.push({
        name: "CluedoNote",
        params: {
          playernames: players,
          cluedo_name: selected_cluedo.value
        }
      })
    }
    return {
      players,
      addPlayer,
      selected_cluedo,
      startCluedo
    }

  }
});
