<template>
  <div class="players">

    <div v-for="(player, i) in players" :key="i" class="player-wrapper">
      <div class="player label"> Spielername</div>
      <input type="text" v-model="players[i]">
    </div>
    <div class="add-player link" @click="addPlayer">
      Spieler hinzufügen
    </div>
  </div>
  <div class="preset">
    <div class="label">
      Welches Cluedo möchtet ihr spielen
    </div>
    <select v-model="selected_cluedo" name="cluedo preset" id="">
      <option value="Reise Cluedo"> Reise Cluedo</option>
      <option value="Harry Potter Cluedo">Harry Potter Cluedo</option>
    </select>
  </div>
  <div to="/cluedo-note" @click="startCluedo" class="start-game">
    Spiel Starten
  </div>
</template>

<script lang="ts">
import router from '@/router';
import { defineComponent, reactive, ref } from 'vue';



export default defineComponent({
  name: 'Home',
  setup(){
    const players: string[] = reactive([])

    const addPlayer = () => {
      players.push("")
    }
    const selected_cluedo = ref("")
    const startCluedo = () => {
      router.push({
        name: "CluedoNote",
        params: {
          playernames: players,
          cluedo_name: selected_cluedo.value
        }
      })
    }
    return {
      players,
      addPlayer,
      selected_cluedo,
      startCluedo
    }

  }
});
</script>

<style scoped>
.link {
  cursor: pointer;
}

.player-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
</style>
